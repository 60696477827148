'use client'

export default function FontPreloadLink() {
  return (
    <link
      rel="preload"
      href="https://fonts.googleapis.com/css2?family=Material+Icons+Outlined&family=Material+Icons&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Material+Symbols+Outlined&family=Material+Symbols+Rounded&display=swap"
      as="style"
      onLoad={e => {
        e.target.onload = null
        e.target.rel = 'stylesheet'
      }}
    />
  )
}
